import { Auth } from 'aws-amplify'
const SERVICE_BASE_URL = getServiceUrl()

export default {
    debug: async function() {
        const authdata = await Auth.currentSession()
        console.log(authdata)
        let resp = await fetch(`${SERVICE_BASE_URL}/debug`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        try {
            resp = await resp.json()
            console.log(resp)
        }
        catch(e) {
            console.log(e.message)
        }
    },

    getGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    createGame: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/create`, {
            method: 'POST',
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    joinGame: async function(gameId, uid) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/join`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'uid': uid}),
        })
        return await resp.json()
    },

    startGame: async function(gameId, uid) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/start`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'uid': uid}),
        })
        return await resp.json()
    },

    addBot: async function(gameId, uid) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bot`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'uid': uid}),
        })
        return await resp.json()
    },

    deleteGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}`, {
            method: 'DELETE',
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    applyRoll: async function(gameId, uid) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/applyRoll`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'uid': uid}),
        })
        return await resp.json()
    },

    reroll: async function(gameId, uid, diceToKeep) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/reroll`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'uid': uid,
                'diceToKeep': diceToKeep,
            }),
        })
        return await resp.json()
    },

    updateStrategy: async function(gameId, uid, strategy, threshold) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/strategy`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'uid': uid, 'strategy': strategy, 'threshold': threshold}),
        })
        return await resp.json()
    },

    getPlayer: async function(playerSub) {
        let resp = await fetch(`${SERVICE_BASE_URL}/player/${playerSub}`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    getSelfPlayer: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/player`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    items: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/items`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    nextTurn: async function(gameId, uid) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/nextTurn`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'uid': uid,
            }),
        })
        return await resp.json()
    },

    buy: async function(gameId, uid, item) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/buy`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'item': item,
                'uid': uid,
            }),
        })
        return await resp.json()
    },

    authHeader: async function() {
        const authdata = await Auth.currentSession()
        return {
            'Authorization': `Bearer ${authdata.getIdToken().getJwtToken()}`
        }
    },
}

function getServiceUrl() {
    return "https://api.marketplaceofgames.com/king"
}
