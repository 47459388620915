import React, { Component } from 'react';
import './App.css';
import { withRouter, useParams } from 'react-router-dom';
import service from './GameService';
import GameLog from './GameLog';
import GamePlayers from './GamePlayers';
import HillStrategy from './HillStrategy';
import Items from './Items';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Checkbox, FormControlLabel, Box, Icon, Typography } from '@material-ui/core';
import './Game.scss';

const POLL_PERIOD = 5000 // milliseconds
const PHASE_BUY = "PHASE_BUY"
const PHASE_ROLL = "PHASE_ROLL"
const NUM_PLAYER_COLORS = 12;

const styles = {
    'loading-screen': {
        'width': '100vw',
        'height': '100vh',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
    },
    'delete-button': {
        'marginRight': '8px'
    }
};

class GameComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gameFull: undefined,
            metadata: undefined,
            gameId: props.gameId,
            found: true,
            errorMessage: undefined,
            mySub: undefined,
            intervalId: undefined,
            diceToKeep: [],
            items: {},
            awaitUpdate: false,
        }
        this.poll = this.poll.bind(this);
        this.handleJoinGame = this.handleJoinGame.bind(this)
        this.handleStartGame = this.handleStartGame.bind(this)
        this.handleAddBot = this.handleAddBot.bind(this)
        this.handleDeleteGame = this.handleDeleteGame.bind(this)
        this.handleKeepDiceUpdate = this.handleKeepDiceUpdate.bind(this)
        this.handleReroll = this.handleReroll.bind(this)
        this.handleApplyRoll = this.handleApplyRoll.bind(this)
        this.updateGameState = this.updateGameState.bind(this)
        this.handleNextTurn = this.handleNextTurn.bind(this)
        this.awaitUpdate = this.awaitUpdate.bind(this)
    }

    async componentDidMount() {
        try {
            const selfplayer = await service.getSelfPlayer()
            const items = await service.items()
            this.setState({
                mySub: selfplayer.sub,
                items: items
            })
            const resp = await service.getGame(this.state.gameId)
            if(resp.message !== undefined) {
                this.setState({
                    errorMessage: resp.message
                })
            }
            else {
                const playerColorMap = resp.game.players.map(assignColors)
                const newState = {
                    gameFull: resp,
                    found: true,
                    errorMessage: undefined,
                    playerColorMap,
                }
                if(this.isMyTurn(resp)) {
                    newState.diceToKeep = reinitDice(resp.game.currentPlayer)
                }
                this.setState(newState)
                if(!resp.game.over) {
                    let intervalId = setInterval(this.poll, POLL_PERIOD)
                    this.setState({intervalId: intervalId})
                }
            }
        } 
        catch(e) {
            console.log(e)
            this.setState({found: false})
        }
    }

    componentWillUnmount() {
        this.clearPolling()
    }
    
    render() {
        if (!this.state.found) {
            return (<p>{this.state.gameId} Not Found!</p>)
        }
        else if (this.state.errorMessage !== undefined) {
            return (<p>{this.state.errorMessage} Not Found!</p>)
        }
        else if (this.state.gameId === undefined) {
            return this.noGameLoaded()
        }
        else if (this.state.gameFull === undefined) {
            return (
                <div style={styles["loading-screen"]}>
                    <CircularProgress size="80px" />
                </div>
            )
        }
        return (<div>
            <GameLog gameLog={this.state.gameFull.game.gameLog} 
                    players={this.state.gameFull.game.players}
                    playerColorMap={this.state.playerColorMap}
                    items={this.state.items}/>
            <Grid container alignItems="center" justify="space-evenly" className="section">
                {this.join()}
                {this.start()}
                {this.addBot()}
            </Grid>
            {this.diceRolls()}
            <GamePlayers game={this.state.gameFull.game}
                    items={this.state.items}
                    playerColorMap={this.state.playerColorMap}/>
            <Items game={this.state.gameFull.game} 
                    mySub={this.state.mySub}
                    isMyTurn={this.isMyTurn()}
                    items={this.state.items}
                    gameId={this.state.gameId}
                    callback={this.awaitUpdate}
                    awaitUpdate={this.state.awaitUpdate}/>
            {this.hillStrategy()}
            {this.delete()}
        </div>)
    }

    join() {
        const myself = this.state.gameFull.game.players.filter(p => {
            return p.sub === this.state.mySub
        })
        if(myself.length === 0) {
            return (
                <Button variant="contained"
                        color="primary"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleJoinGame()}}>
                    Join Game
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    start() {
        const canStart = this.state.gameFull.game.currentPlayer === undefined
                && this.state.gameFull.game.players.length > 1
                && this.state.gameFull.owner === this.state.mySub
        if(canStart) {
            return (
                <Button variant="contained"
                        color="primary"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleStartGame()}}>
                    Start Game
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    addBot() {
        const canAddBot = this.state.gameFull.game.currentPlayer === undefined
                && this.state.gameFull.owner === this.state.mySub
        if(canAddBot) {
            return (
                <Button variant="contained"
                        color="primary"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleAddBot()}}>
                    Add Bot
                </Button>
            )
        }
        else {
            return (null)
        }
    }
    /**
     * Render a delete button
     */
    delete() {
        if(this.state.gameFull.game.over || this.state.gameFull.owner === this.state.mySub) {
            return (
                <Grid container justify="flex-end" className="section">
                    <Button variant="contained"
                            color="primary"
                            startIcon={<Icon>delete</Icon>}
                            size="small"
                            style={styles["delete-button"]}
                            onClick={() => {this.handleDeleteGame()}}>
                        Delete Game
                    </Button>
                </Grid>
            )
        }
        else {
            return (null)
        }
    }
    /**
     * @param {GameFull} gameFull - {owner: <string>, game: <Game>, uuid: <string>}. Defaults to current state game
     * @return {boolean} - true if current player is the this app
     */
    isMyTurn(gameFull) {
        if(gameFull === undefined) {
            gameFull = this.state.gameFull
        }
        return gameFull.game.currentPlayer !== undefined 
                && gameFull.game.players[gameFull.game.currentPlayer.playerIndex] !== undefined
                && gameFull.game.players[gameFull.game.currentPlayer.playerIndex].sub === this.state.mySub
    }
    /**
     * Render dice related actions
     */
    diceRolls() {
        if(this.isMyTurn() && !this.state.gameFull.game.over) {
            if(this.state.gameFull.game.phase === PHASE_ROLL) {
                const currentHand = this.state.gameFull.game.currentPlayer.roll.map( (die, dieNum) => {
                    const key = `hand_die${dieNum}`
                    const isChecked = this.state.diceToKeep[dieNum]
                    return (
                        <span className="die" key={key} onClick={() => {
                            this.handleKeepDiceUpdate(dieNum)
                        }}>
                            <FormControlLabel
                                control={<Checkbox checked={isChecked} readOnly/>}
                                label={die}
                                labelPlacement="top"
                            />
                        </span>
                    )
                })
                return (
                    <Grid container direction="column" alignItems="center">
                        <Grid item container justify="center" wrap="wrap">
                            {currentHand}
                        </Grid>
                        <Box mt="10px">
                            <Button
                                disabled={this.state.awaitUpdate} 
                                onClick={this.handleReroll}
                                variant="contained"
                                color="primary"
                                style={{'marginRight': '36px'}}
                            >
                                Reroll Unchecked
                            </Button>
                            <Button
                                disabled={this.state.awaitUpdate} 
                                onClick={this.handleApplyRoll}
                                variant="contained"
                                color="primary"
                            >
                                Keep All Dice
                            </Button>
                        </Box>
                    </Grid>
                )
            }
            else if(this.state.gameFull.game.phase === PHASE_BUY) {
                const currentHand = this.state.gameFull.game.currentPlayer.roll.map( (die, dieNum) => {
                    const key = `hand_die${dieNum}`
                    return (<span className="dieNoCheckBox" key={key}>{die}</span>)
                })
                return (
                    <Grid container
                          direction="column"
                          alignItems="center" 
                          justify="center"
                          className="section">
                        <Typography>{currentHand}</Typography>
                        <Button variant="contained"
                                color="primary"
                                disabled={this.state.awaitUpdate}
                                onClick={this.handleNextTurn}>
                            End Turn
                        </Button>
                    </Grid>
                )
            }
        }
        else {
            return (<div/>)
        }
    }
    /**
     * Render a block to make changes to the hill strategy
     */
    hillStrategy() {
        const myPlayer = this.state.gameFull.game.players.filter( p => {
            return p.sub === this.state.mySub
        })[0]
        if(myPlayer === undefined) {
            // not in this game
            return (<span></span>)
        }
        return (<HillStrategy 
                updateGameState={this.updateGameState} 
                gameId={this.state.gameId} 
                uid={this.state.gameFull.game.uid} 
                myPlayer={myPlayer}
                gameOver={this.state.gameFull.game.over}/>)
    }

    async handleJoinGame() {
        try {
            this.awaitUpdate( async () => {
                return await service.joinGame(this.state.gameId, this.state.gameFull.game.uid)
            })
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleStartGame() {
        try {
            this.setState({awaitUpdate: true})
            const resp = await service.startGame(this.state.gameId, this.state.gameFull.game.uid)
            this.updateGameState(resp, true)
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleAddBot() {
        try {
            this.setState({awaitUpdate: true})
            const resp = await service.addBot(this.state.gameId, this.state.gameFull.game.uid)
            this.updateGameState(resp, true)
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }
    
    async handleDeleteGame() {
        try {
            await service.deleteGame(this.state.gameId)
            this.props.history.push(`/`);
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }
    /**
     * Keep all the dice in hand - skip any rerolls
     */ 
    async handleApplyRoll() {
        try {
            this.awaitUpdate(async () => {
                return await service.applyRoll(this.state.gameId, this.state.gameFull.game.uid)
            })
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }
    /**
     * End buy phase
     */
    async handleNextTurn() {
        try {
            this.awaitUpdate(async () => {
                return await service.nextTurn(this.state.gameId, this.state.gameFull.game.uid)
            })
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }
    /**
     * Change state on which dice to keep
     */
    handleKeepDiceUpdate(dieNum) {
        const newState = this.state.diceToKeep.map( (v, index) => {
            return (index === dieNum) ? !v : v
        })
        this.setState({ diceToKeep: newState })
    }
    /**
     * Reroll any unchecked dice
     */ 
    async handleReroll() {
        try {
            const diceToKeep = this.state.diceToKeep.reduce( (acc, bool, index) => {
                if(bool) {
                    acc.push(this.state.gameFull.game.currentPlayer.roll[index])
                }
                return acc
            }, [])
            this.awaitUpdate( async () => {
                return await service.reroll(this.state.gameId, this.state.gameFull.game.uid, diceToKeep)
            })
        }
        catch(e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }
    /**
     * Poll for updates to the game
     */
    async poll() {
        // don't poll since this player is in control
        if(!this.isMyTurn()) {
            try {
                const resp = await service.getGame(this.state.gameId)
                this.updateGameState(resp)
            }
            catch(e) {
                console.log(`${new Date().toISOString()}: ${e.message}`)
            }
        }
    }
    /**
     * Change game state as necessary
     * @param {GameFull} newGameFull 
     * @param {boolean} gameStart - optional. if true, do dice init if appropiate
     */
    updateGameState(newGameFull, gameStart) {
        const hasUidChanged = (newGameFull.game.uid !== this.state.gameFull.game.uid) || gameStart
        const newState = { 
            gameFull: newGameFull,
            awaitUpdate: false,
        }
        // in a game hasn't started, but player array is greater than the color map
        if(newGameFull.game.players.length > this.state.playerColorMap.length) {
            newState.playerColorMap = newGameFull.game.players.map(assignColors)
        }

        if(hasUidChanged && this.isMyTurn(newGameFull)) {
            newState.diceToKeep = reinitDice(newGameFull.game.currentPlayer)
        }
        this.setState(newState)
        if(newGameFull.game.over) {
            this.clearPolling()
        }
    }
    /**
     * Callback should return a gameFull object. Sets the awaitUpdate flag to true before 
     * service returns, then false when data is returned
     * @param {async Function<GameFull>} callback 
     */
    async awaitUpdate(callback) {
        this.setState({awaitUpdate: true})
        this.updateGameState(await callback())
    }
    /**
     * Stops the interval call, as necessary. This should only happen if the game is ended
     */
    clearPolling() {
        if(this.state.intervalId !== undefined) {
            clearInterval(this.state.intervalId)
            this.setState({intervalId: undefined})
        }
    }
}

function reinitDice(currentPlayer) {
    let diceToKeep = []
    for(let i = 0; i < currentPlayer.roll.length; i++) {
        diceToKeep.push(true)
    }
    return diceToKeep
}

function assignColors(p, pin) {
    return `playerColor${(pin % NUM_PLAYER_COLORS)}`
}

export { GameComponent }

export default function Game() {
    let { gameId } = useParams()
    const GameComponentWithRouter = withRouter(GameComponent)
    return (<GameComponentWithRouter gameId={gameId} />)
}