import React, {Component} from 'react';
import './App.css';
import { withRouter } from "react-router-dom";
import service from "./GameService";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from '@material-ui/core';

class NoGameComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      joinGameId: '',
      games: undefined,
      disableCreateGame: false,
    }
    this.handleCreateGame = this.handleCreateGame.bind(this)
    this.handleJoinGameSubmit = this.handleJoinGameSubmit.bind(this)
    this.handleJoinGameText = this.handleJoinGameText.bind(this)
  }

  async componentDidMount() { 
    const selfPlayer = await service.getSelfPlayer();
    if(selfPlayer.error) {
      console.log(selfPlayer.error);
    }
    else {
      this.setState({ 
        games: selfPlayer.games
      });
    }
  }

  render() {
    return (
      <Container maxWidth="sm">
        <Grid container direction="column" justify="space-evenly" alignItems="center">
          <Typography variant="h3" style={{'margin': '16px 0 10px 0'}}>
            King Of The Hill
          </Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>add</Icon>}
            disabled={this.state.disableCreateGame}
            onClick={() => {this.handleCreateGame()}}
          >
            Create A New Game
          </Button>

          <form onSubmit={this.handleJoinGameSubmit}>
            <Grid container justify="center" alignItems="flex-end" style={{'margin': '60px 0 46px 0'}}>
              <TextField label="Game Id" value={this.state.joinGameId} onChange={this.handleJoinGameText}/>
              <Button variant="contained" color="primary" type="submit" style={{'marginLeft': '12px'}}>
                View Game
              </Button>
            </Grid>
          </form>

          {this.showMyGames()}
        </Grid>
      </Container>
    )
  }

  showMyGames() {
    if(!this.state.games) {
      return (<div style={{'marginTop': '16px'}}><CircularProgress /></div>)
    }
    if(this.state.games.length === 0) {
      return (
        <Typography variant="h6">
          No existing games found.
        </Typography>
      )
    }
    return (
      <div>
        <Typography variant="h5">
          Current Games:
        </Typography>

        {this.state.games.map(this.showGameLink)}
      </div>
    )
  }
  /**
   * @param {String} game - uuid of a game
   */
  showGameLink(game) {
    const key = `game_${game}`
    const gameLink = `/game/${game}`
    return (<div key={key}>
      <a href={gameLink}>{game}</a>
      </div>)
  }

  async handleCreateGame() {
    await this.setState({disableCreateGame: true})
    const resp = await service.createGame()
    if(resp.uuid !== undefined) {
      this.props.history.push(`/game/${resp.uuid}`);
    }
  }

  handleJoinGameSubmit(event) {
    const gameId = this.state.joinGameId.trim()
    if(gameId !== '') {
      this.props.history.push(`/game/${gameId}`);
    }
  }
  
  handleJoinGameText(event) {
    this.setState({
      joinGameId: event.target.value
    })
  }
}

export default withRouter(NoGameComponent)