import React  from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Game from './Game'
import NoGame from './NoGame'
import './App.css';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { createMuiTheme } from '@material-ui/core/styles';
import { deepPurple, teal } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import '@aws-amplify/ui/dist/style.css';

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: teal,
    type: 'dark'
  },
});

Amplify.configure({
  Auth: {
      userPoolId: 'us-east-1_MB6IiEn9d',
      userPoolWebClientId: '5jbe0d4u1q8p6p15edmno7o31h',
      region: 'us-east-1', 
  }
});

const signUpConfig = {
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'string'
    }
  ]
};


function App() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route path="/game/:gameId" ><Game/></Route>
            <Route path="/" ><NoGame/></Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    )
}

export default withAuthenticator(App, { signUpConfig })